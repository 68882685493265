var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":_vm.itemId === 'new' ? _vm.$t('create') : _vm.$t('save'),"icon":_vm.getAppIcon(_vm.itemApp),"icon-color":_vm.getAppColor(_vm.itemApp),"title":((_vm.itemId === 'new' ? _vm.$t('nnew') : _vm.$t('edit')) + " " + (_vm.APP_INVENTORY_TITLE[_vm.itemApp])),"max-width":450},on:{"save":_vm.loadItems},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [(['terraform', 'tofu'].includes(_vm.itemApp))?_c('TerraformInventoryForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}}):_c('InventoryForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,1976206586),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('ObjectRefsDialog',{attrs:{"object-title":"inventory","object-refs":_vm.itemRefs,"project-id":_vm.projectId},model:{value:(_vm.itemRefsDialog),callback:function ($$v) {_vm.itemRefsDialog=$$v},expression:"itemRefsDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('deleteInventory'),"text":_vm.$t('askDeleteInv')},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('inventory')))]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.can(_vm.USER_PERMISSIONS.manageProjectResources))?_c('v-btn',_vm._g(_vm._b({staticClass:"pr-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('newInventory'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()]}}],null,false,2744150740)},[_c('v-list',_vm._l((_vm.apps),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){_vm.itemApp = item; _vm.editItem('new');}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.getAppColor(item)}},[_vm._v(_vm._s(_vm.getAppIcon(item))+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.APP_INVENTORY_TITLE[item]))])],1)}),1)],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getAppIcon(_vm.getAppByType(item.type)))+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.type))])]}},{key:"item.inventory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(['file', 'terraform-workspace'].includes(item.type) ? item.inventory : '—')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.itemApp = _vm.getAppByType(item.type); _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,3202099476)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }