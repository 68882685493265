var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null && _vm.isAppsLoaded)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":"Save","title":_vm.$t('Edit App')},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('AppForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,1196565541),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('Delete App'),"text":_vm.$t('Do you really want to delete this app?')},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){return _vm.returnToProjects()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Applications')))]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.isAdmin,"color":"primary"},on:{"click":function($event){return _vm.editItem('')}}},[_vm._v(_vm._s(_vm.$t('New App')))])],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20] }},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":!_vm.isAdmin,"inset":""},on:{"change":function($event){return _vm.setActive(item.id, item.active)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getAppIcon(item.id))+" ")]),_vm._v(" "+_vm._s(_vm.getAppTitle(item.id))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(!_vm.isDefaultApp(item.id))?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","disabled":item.id === _vm.userId},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,2354303362)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }